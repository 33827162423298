export const REMISORIA = {
  // TIPO RAMO
  TIPO1_OPTIONS: [
    { text: "Nuevo Negocio", value: "1" },
    { text: "Renovación", value: "2" },
    { text: "Adición", value: "3" },
    { text: "Interés de Prestamo", value: "4" },
    { text: "Prestamo", value: "5" }
  ],
  // TIPO REGISTRO
  TIPO2_OPTIONS: [
    { text: "Poliza Nueva", value: "1" },
    { text: "Póliza Existente", value: "2" }
  ],
  FORMA_PAGO_OPTIONS: [
    { text: "TC Internacional", value: "1" },
    { text: "Cheque Electrónico", value: "2" },
    { text: "Cheque Banco Intern", value: "3" },
    { text: "Cheque Local Intern", value: "4" },
    { text: "Transferencia", value: "5" },
    { text: "Valor de Rescate", value: "6" },
    { text: "Ninguna", value: "" }
  ],
  TIPO_POLIZA_OPTIONS: [
    { text: "Salud", value: "H" },
    { text: "Vida", value: "L" },
    { text: "Viaje", value: "T" }
  ],
  MODO_PAGO_OPTIONS: [
    { text: "Anual", value: "1" },
    { text: "Semestral", value: "2" },
    { text: "Trimestral", value: "4" },
    { text: "Mensual", value: "12" },
    { text: "Diario", value: "360" },
    { text: "Debito Directo", value: "20" },
    { text: "Prima Unica", value: "21" },
    { text: "Mensual T/C", value: "12TC" }
  ],
  STATUS_VIGENCIA_OPTIONS: [
    { text: "Vigente", value: "V" },
    { text: "No Vigente", value: "NV" }
  ]
} as const;
