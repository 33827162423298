<template>
  <v-dialog v-bind:value="dialog" persistent max-width="600px">
    <v-form>
      <v-card>
        <v-card-title>
          <span class="headline">{{
            dtoSave.id ? "Editar" : "Crear nuevo"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="# Remisoria"
                  v-model.number="dtoSave.numero"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="# Subpartida"
                  v-model.number="dtoSave.subpartida"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <DateTimePicker label="Fecha y Hora" v-model="dtoSave.fecha" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Tipo Ramo"
                  :items="REMISORIA.TIPO1_OPTIONS"
                  :item-text="'text'"
                  :item-value="'value'"
                  v-model="dtoSave.tipo1"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Tipo Registro"
                  :items="REMISORIA.TIPO2_OPTIONS"
                  :item-text="'text'"
                  :item-value="'value'"
                  v-model="dtoSave.tipo2"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Observaciones"
                  v-model="dtoSave.observacion"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <DateTimePicker
                  label="Fecha Remisoria"
                  v-model="dtoSave.fechaEmision"
                />
              </v-col>
              <!--
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Agente General"
                    value="756"
                    required
                    readonly
                  ></v-text-field>
                </v-col>
                -->
              <v-col cols="12" sm="6">
                <v-select
                  label="Agente"
                  v-model="dtoSave.agente"
                  :items="agentes"
                  :item-text="'nombre'"
                  :item-value="'id'"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Subagente"
                  v-model="dtoSave.subagente"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Forma Pago"
                  :items="REMISORIA.FORMA_PAGO_OPTIONS"
                  :item-text="'text'"
                  :item-value="'value'"
                  v-model="dtoSave.formaPago"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Número Poliza"
                  v-model="dtoSave.numeroPoliza"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <DateTimePicker
                  label="Fecha Emisión Poliza"
                  v-model="dtoSave.fechaEmisionPoliza"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Asegurado Principal"
                  v-model="dtoSave.nombreAseguradoPrincipal"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                Asegurados Adicionales:
                <v-selectize
                  v-model="dtoSave.nombreAseguradoAdicional"
                  multiple
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Tipo Poliza"
                  :items="REMISORIA.TIPO_POLIZA_OPTIONS"
                  :item-text="'text'"
                  :item-value="'value'"
                  v-model="dtoSave.tipoPoliza"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Plan"
                  v-model="dtoSave.plan"
                  :items="planes"
                  :item-text="'detalle'"
                  :item-value="'id'"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Modo Pago"
                  :items="REMISORIA.MODO_PAGO_OPTIONS"
                  :item-text="'text'"
                  :item-value="'value'"
                  v-model="dtoSave.modoPago"
                ></v-select>
              </v-col>
              <!--
              <v-col cols="12" sm="6">
                <v-select
                  label="Compañia"
                  :items="compañiaOptions"
                  :item-text="'text'"
                  :item-value="'value'"
                  v-model="dtoSave.compañia"
                ></v-select>
              </v-col>
              -->
              <v-col cols="12" sm="6">
                <v-select
                  label="Compañia"
                  v-model="dtoSave.compañia"
                  :items="compañias"
                  :item-text="'nombre'"
                  :item-value="'id'"
                  required
                ></v-select>
              </v-col>
              <!-- solo contable -->
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Prima Pagada"
                  v-model.number="dtoSave.primaPagada"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Prima Anual"
                  v-model.number="dtoSave.primaAnual"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <!-- nuevos campos -->
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Derecho Agencia"
                  v-model.number="dtoSave.derechoAgencia"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Derecho Emisión"
                  v-model.number="dtoSave.derechoEmision"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="false">
              <v-col cols="12" sm="6">
                <v-text-field
                  label="% Agencia Nuevo"
                  v-model.number="dtoSave.porcentajeAgenciaNuevo"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="% Agencia Renovación"
                  v-model.number="dtoSave.porcentajeAgenciaRenovacion"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="% Agente Nuevo"
                  v-model.number="dtoSave.porcentajeAgenteNuevo"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="% Agente Renovación"
                  v-model.number="dtoSave.porcentajeAgenteRenovacion"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Estado Vigencia"
                  :items="REMISORIA.STATUS_VIGENCIA_OPTIONS"
                  :item-text="'text'"
                  :item-value="'value'"
                  v-model="dtoSave.statusVigencia"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>* campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()" v-if="false">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { REMISORIA } from "@/shared/constants";
import DateTimePicker from "@/components/shared/DateTimePicker.vue";
import RemisoriaService from "@/api/remisoria/RemisoriaService";
import PlanService from "@/api/plan/PlanService";
//import "selectize/dist/css/selectize.css";
//import VSelectize from "@isneezy/vue-selectize";
export default {
  components: {
    DateTimePicker
    //VSelectize
  },
  props: ["dialog", "dato"],
  data() {
    return {
      REMISORIA,
      datetime: new Date(),
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      dtoSave: {
        observacion: "",
        primaPagada: 0.0,
        primaAnual: 0.0,
        derechoAgencia: 0.0,
        derechoEmision: 0.0,
        porcentajeAgenciaNuevo: 0.0,
        porcentajeAgenciaRenovacion: 0.0,
        porcentajeAgenteNuevo: 0.0,
        porcentajeAgenteRenovacion: 0.0,
        fecha: new Date(),
        fechaEmision: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        fechaEmisionPoliza: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      agentes: [],
      planes: [],
      compañias: []
    };
  },
  methods: {
    dialogChange(value) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    buscar() {
      this.search = this.filtro;
      this.fetchData();
    },
    fetchData() {
      console.log("llamando api");
      //axios.get("data/datos.json").then(response => {
      axios
        .get(
          this.url +
            "/datos-base/paginado?page=" +
            this.page +
            "&limit=10" +
            "&search=" +
            this.search
        )
        .then(response => {
          this.datos = response.data?.items || [];
          this.pages = response.data?.meta?.totalPages || 0;
        });
    },
    loadAgentes() {
      axios.get(this.url + "/agentes-base").then(response => {
        this.agentes = response.data || [];
        this.agentes.sort(function(a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (b.nombre > a.nombre) {
            return -1;
          }
          return 0;
        });
        //this.pages = response.data?.meta?.totalPages || 0;
      });
    },
    loadCompañias() {
      axios.get(this.url + "/compania-base").then(response => {
        this.compañias = response.data || [];
        this.compañias.sort(function(a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (b.nombre > a.nombre) {
            return -1;
          }
          return 0;
        });
        //this.pages = response.data?.meta?.totalPages || 0;
      });
    },
    loadPlanes() {
      const params = new URLSearchParams({
        status: "1"
      }).toString();
      PlanService.getAll(params)
        .then(response => {
          this.planes = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Planes",
            text: "No se pudo obtener"
          });
        });
    },
    save() {
      const dto = { ...this.dtoSave };
      dto.fecha = dto.fecha.toISOString();
      dto.fechaEmision = dto.fechaEmision.toISOString();
      dto.fechaEmisionPoliza = dto.fechaEmisionPoliza.toISOString();
      RemisoriaService.createRemisoria(dto)
        .then(() => {
          //
          this.$emit("saved", "someValue");
          this.close();
        })
        .catch(error => {
          //
          let mensaje = "Intente más tarde";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Remisoria no se pudo guardar",
            text: mensaje
          });
          //
        });
      /*
      const data = JSON.parse(JSON.stringify(this.dtoSave));
      console.log(data);
      axios
        .post(this.url + "/datos-base", data)
        .then(response => {
          console.log(response);
          this.dialog = false;
        })
        .catch(error => {
          console.log(error);
        });
        */
    }
    //nuevo
  },
  created() {
    //this.fetchData();
    this.dtoSave = {};
    this.dtoSave = { ...this.dato };
    this.loadAgentes();
    this.loadPlanes();
    this.loadCompañias();
  }
};
</script>
