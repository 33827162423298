import httpClient from "../http-client";

const ENDPOINT = "/remisoria-renovacion";

class RemisoriaRenovacionService {
  uploadExcel(formData: FormData) {
    return httpClient.post(`${ENDPOINT}/upload-excel`, formData);
  }

  /*
    getRemisoriasExcel() {
    return httpClient.get(`${ENDPOINT}/excel`, {
      responseType: "blob"
    });
  }

  
  createRemisoria(dto: any) {
    return httpClient.post(ENDPOINT, dto);
  }
  */
}
export default new RemisoriaRenovacionService();
