<template>
  <v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ textSucess }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ textError }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-card-title class="justify-center">
      <!--
      <form>
        <v-text-field v-model="filtro" label="Buscar:" required></v-text-field>
        <v-btn class="mr-4" @click="buscar()">
          Buscar
        </v-btn>
      </form>
      -->

      <v-row>
        <v-col cols="12">
          <v-file-input
            v-model="file"
            placeholder="Upload your .xlsx documents"
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-btn
            color="primary"
            @click="uploadFile()"
            :disabled="isDisabled"
            :loading="isUploading"
            >Subir</v-btn
          >
        </v-col>

        <v-col cols="12" sm="4">
          <DatePicker v-model="fechaInicio" :label="'Fecha Inicio'" />
        </v-col>
        <v-col cols="12" sm="4">
          <DatePicker v-model="fechaFin" :label="'Fecha Fin'" />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="numeroPoliza" label="# Poliza"></v-text-field>
        </v-col>
        <v-col cols="12" class="my-auto text-center">
          <v-btn color="primary" dark @click="fetchData()">
            Buscar
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" class="text-center" v-if="false">
          <v-btn color="primary" dark class="mb-2" @click="downloadExport()">
            <v-icon left>
              mdi-download
            </v-icon>
            Descargar
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" class="text-center" v-if="false">
          <v-btn color="primary" @click="newItem()">Nuevo Registro</v-btn>
        </v-col>
      </v-row>
      <RemisoriaAgregar
        v-if="dialog"
        @saved="fetchData()"
        @dialogChange="dialogChange"
        :dialog="dialog"
        :dato="dato"
      />
    </v-card-title>
    <v-data-table :headers="headers" :items="datos" hide-default-footer>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"
          >mdi-information</v-icon
        >
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pages"
      v-on:input="fetchData()"
    ></v-pagination>
  </v-card>
</template>

<script>
import axios from "axios";
import RemisoriaAgregar from "@/components/RemisoriaRenovacionAgregar.vue";
import RemisoriaService from "@/api/remisoria/RemisoriaService";
import moment from "moment";
import DatePicker from "@/components/shared/DatePïcker.vue";
import RemisoriaRenovacionService from "@/api/remisoria/RemisoriaRenovacionService";

export default {
  components: {
    DatePicker,
    RemisoriaAgregar
  },
  data() {
    return {
      isUploading: false,
      fechaInicio: new Date().toISOString().substr(0, 10),
      fechaFin: new Date().toISOString().substr(0, 10),
      numeroPoliza: "",
      dato: {},
      dialog: false,
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "# Remisoria", value: "numero" },
        { text: "# Subpartida", value: "subpartida" },
        { text: "# Poliza", value: "numeroPoliza" },
        { text: "Asegurado", value: "nombreAseguradoPrincipal" },
        { text: "updatedBy", value: "updatedBy.getFullNameDisplay" },
        { text: "Acción", value: "actions", sortable: false }
      ],
      file: null,
      snackbar: false,
      textSucess: "Archivo subido.",
      timeout: 2000,
      textError: "Error al subir archivo."
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        observacion: "",
        primaPagada: 0.0,
        primaAnual: 0.0,
        derechoAgencia: 0.0,
        derechoEmision: 0.0,
        porcentajeAgenciaNuevo: 0.0,
        porcentajeAgenciaRenovacion: 0.0,
        porcentajeAgenteNuevo: 0.0,
        porcentajeAgenteRenovacion: 0.0
      };
      this.dato = newDto;
    },
    dialogChange(value) {
      this.dialog = value;
    },
    editItem(item) {
      this.dato = { ...item };
      this.dato.fecha = new Date(this.dato.fecha);
      this.dato.fechaEmision = new Date(this.dato.fechaEmision);
      this.dato.fechaEmisionPoliza = new Date(this.dato.fechaEmisionPoliza);
      this.dato.agente = this.dato.agente.id;
      this.dato.plan = this.dato.plan.id;
      this.dato.compañia = this.dato.compañia.id;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    buscar() {
      this.search = this.filtro;
      //this.fetchData();
    },
    fetchData() {
      console.log("llamando api");
      const params = new URLSearchParams({
        fechaInicio: this.fechaInicio,
        fechaFin: this.fechaFin,
        numeroPoliza: this.numeroPoliza
      }).toString();
      //axios.get("data/datos.json").then(response => {
      axios
        .get(
          this.url +
            "/remisoria-renovacion/paginado?page=" +
            this.page +
            "&limit=10" +
            "&search=" +
            this.search +
            "&" +
            params
        )
        .then(response => {
          this.datos = response.data?.items || [];
          this.pages = response.data?.meta?.totalPages || 0;
        });
    },
    uploadFile() {
      this.isUploading = true;
      const formData = new FormData();
      formData.append("file", this.file);
      /*
      axios
        .post(this.url + "/remisoria-renovacion/upload-excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
      */
      RemisoriaRenovacionService.uploadExcel(formData)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Pago",
            text: "Procesado de forma exitosa"
          });
        })
        .catch(error => {
          //let mensaje = error.response.data.message 'por algun motivo'
          let mensaje = "Intente más tarde";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Pago",
            text: "No se pudo procesar el archivo: " + mensaje
          });
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
    downloadExport() {
      RemisoriaService.getRemisoriasExcel()
        .then(response => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          const today = new Date();
          moment.locale("es");
          link.setAttribute(
            "download",
            `remisoria-_${moment(today).format("YYYY-MMMM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          console.log("no se pudo generar el documento");
        });
    }

    //nuevo
  },
  created() {
    this.fetchData();
  },
  computed: {
    isDisabled: function() {
      if (this.file != null) return false;
      return true;
    }
  }
};
</script>
