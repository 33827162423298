var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.textSucess)+" ")]),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.textError)+" ")]),_c('v-card-title',{staticClass:"justify-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"placeholder":"Upload your .xlsx documents","prepend-icon":"mdi-paperclip"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isDisabled,"loading":_vm.isUploading},on:{"click":function($event){return _vm.uploadFile()}}},[_vm._v("Subir")])],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('DatePicker',{attrs:{"label":'Fecha Inicio'},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('DatePicker',{attrs:{"label":'Fecha Fin'},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"# Poliza"},model:{value:(_vm.numeroPoliza),callback:function ($$v) {_vm.numeroPoliza=$$v},expression:"numeroPoliza"}})],1),_c('v-col',{staticClass:"my-auto text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.fetchData()}}},[_vm._v(" Buscar ")])],1),(false)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.downloadExport()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(" Descargar ")],1)],1):_vm._e(),(false)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.newItem()}}},[_vm._v("Nuevo Registro")])],1):_vm._e()],1),(_vm.dialog)?_c('RemisoriaAgregar',{attrs:{"dialog":_vm.dialog,"dato":_vm.dato},on:{"saved":function($event){return _vm.fetchData()},"dialogChange":_vm.dialogChange}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.datos,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-information")])]}}],null,true)}),_c('v-pagination',{attrs:{"length":_vm.pages},on:{"input":function($event){return _vm.fetchData()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }